import { useZenniFetch } from '../useZenniFetch'
import type { Endpoints } from '../../types/sdk'

export const useSdk = () => {
  const target = {}
  const handler = {
    get(_target: any, prop: string) {
      return async (data: any) =>
        await useZenniFetch(`/api/cartService/${prop}`, {
          method: 'POST',
          body: data,
        })
    },
  }
  const cartService = new Proxy(target, handler) as Endpoints
  return {
    cartService,
  }
}
